@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: "Roboto";
}

.header {
  background-color: #1e3953;
  padding: 15px;
}
.header .row button {
  font-size: 10px;
}
.header .row > .icons {
  color: white;
}
.header .fa {
  color: #93c83f;
}
.header .icons .fa span {
  margin-left: 2px;
  color: white;
}
.header .icons a {
  text-decoration: none;
  color: white;
}
.icon {
  color: #93c83f;
}
#logo {
  width: 50px;
  margin-right: 5px;
}

.footer {
  margin-top: 30px;
}
.footer .col1,
.footer .col2,
.footer .col3 {
  color: white;
}

.footer .col2 > a {
  color: white;
}
.footer .col2 a {
  color: white;
  text-decoration: none;
}
.footer .col3 > h3 {
  color: #93c83f;
}
.footer button,
.contact-form button {
  background-color: #93c83f;
  border: none;
}
.footer button:hover,
.contact-form button:hover {
  background-color: #566938;
}
.footer .icon {
  margin-right: 5px;
}
.copyright {
  color: white;
  padding: 10px;
  text-align: center;
  background-color: rgb(24, 24, 24);
}
.h3 {
  font-size: 30px;
  font-weight: bold;
}
.h3-foot {
  font-size: 25px;
  font-weight: 400;
}
.h4 {
  font-size: 25px;
}
.about-school p {
  font-size: 17px;
  gap: 8px;
}
.about-school img {
  width: 80vh;
}
.members-area {
  text-align: center;
}
.members-area .underline {
  border-bottom: 2px solid #93c83f;
}
.list-group .list-group-item {
  cursor: pointer;
  height: 60px;
  display: flex;
  align-items: center;
  border: 1px solid #ececec;
  overflow: hidden;
}

.rules li {
  margin-bottom: 5px;
  font-size: 16px;
}
.contact-form {
  background-color: #1e3953;
  padding-bottom: 20px;
  color: #93c83f;
}

.form-group .input-area {
  height: 50px;
  background-color: #1e3953;
  letter-spacing: 2px;
  color: #b4b4b4;
}

.form-group .message {
  height: 150px;
}
.form-group ::placeholder {
  color: #b4b4b4;
}
.mapframe {
  border: 2px solid #93c83f;
}
.contact-icons .icon {
  font-size: 25px;
}
.contact-icons a {
  text-decoration: none;
  color: inherit;
}
.msg-text-area {
  width: 100%;
  height: 150px;
  background-color: #1e3953;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 18px;
  padding: 10px;
}
.sent-msg {
  color: #93c83f;
}
@media screen and(max-width:550px) {
  .download {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.popup {
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 2000;
  pointer-events: none;
  transition: opacity 1s;
}

.popup__img {
  position: absolute;
  height: 70%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  object-fit: contain;
}

@media (max-width: 800px) {
  .popup__img {
      width: 95%;
  }
}

.popup__hidden {
  opacity: 0;
  transform: scale(0);
}


.popup__carousel {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000070;
}

.popup__carousel__dismiss {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: #ef4444;
  color: #fff;
  padding: 8px;
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99999999px;
}

.popup__carousel__buttons svg {
  height: 32px;
}

.popup__carousel__prev,
.popup__carousel__next {
  position: absolute;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  height: 48px;
  width: 48px;
  border-radius: 9999999px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  translate: 0 -50%;
}

.popup__carousel__prev {
  left: 20px;
}

.popup__carousel__next {
  right: 20px;
}

.popup__carousel__img {
  height: 70vh;
  border-radius: 5px;
  object-fit: contain;
}

.popup__carousel__img--link {
  position: relative;
}

.popup__carousel__img--link svg {
  height: 32px;
  color: #fff;
  position: absolute;
  right: 12px;
  top: 12px;
  opacity: .6;
  transition: opacity .2s ease;
}

.popup__carousel__img--link span {
  position: absolute;
  top: 100%;
  width: 100%;
  text-align: center;
  color: #adadad;
}

.popup__carousel__img--link:hover svg {
  opacity: 1;
}

.popup__carousel__dots {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  left: 50%;
  translate: -50% 0;
  bottom: 40px;
}

.popup__carousel__dots span {
  height: 8px;
  width: 8px;
  border-radius: 99999999px;
  background-color: #ffffff30;
  cursor: pointer;
}

.popup__carousel__dots span.active {
  background-color: #fff;
  cursor: auto;
}

.popup__carousel__hidden {
  opacity: 0;
  transform: scale(0);
}